<template>
  <div></div>
</template>

<script>
import {mapState, mapGetters, mapActions} from 'vuex'

export default {
    name: 'cart',
    computed: {
        ...mapGetters('client', ['isMember', 'isTokenOutOfDate']),
        ...mapState('cart', [
            'goodList',
            'showCart'
        ]),
        ...mapGetters('cart', ['cartTotal', 'cartMemberTotal']),
    },
    methods: {
        ...mapActions('cart', [
            'setShowCart',
            'clearGoods'
        ])
    }
}
</script>

<style>

</style>