<template>
  <base-mobile-page>
    <template v-slot:main>
        <el-main style="padding:40px 20px;margin:0 auto;width:100%;max-width:1300px;">
            <big-cart-item v-for="item in goodList" :key="item.key" :item="item"></big-cart-item>
            <el-divider></el-divider>
            <div style="text-align:right;">
                <span class="cart-total-price-4" v-if="isMember">TOTAL: ${{cartMemberTotal}}</span>
                <span class="cart-total-price-4" v-else>TOTAL: ${{cartTotal}}</span>
            </div>
            <div style="text-align:right;">
                <el-button type="danger" style="width:200px;margin-top:10px;" @click="checkoutClick">Check out</el-button>
            </div>
        </el-main>
    </template>
  </base-mobile-page>
</template>

<script>
import ModelCart from '../viewmodel/ModelCart.vue'
import BaseMobilePage from '../../components/base/BaseMobilePage.vue'
import BigCartItem from '../../components/mobile/BigCartItem.vue'

export default {
    extends: ModelCart,
    components: {
        BaseMobilePage,
        BigCartItem
    },
    methods: {
        checkoutClick(){
            if(this.goodList.length == 0) return
             this.setShowCart({
                showCart: false
            })
            if(this.isTokenOutOfDate){
                this.$message({
                    message: 'Login is needed before checkout',
                    type: 'warning'
                })
                window.location.href = '/login?callbackUrl=' + encodeURI('/checkout')
            } else {
                window.location.href = '/checkout'
            }
        }
    }
}
</script>

<style>

</style>
BaseMobilePage